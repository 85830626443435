import React, {useContext} from 'react';
import { MQTTContext } from './MQTTConn';
import { ResponsiveContainer } from 'recharts';
import './Diagram.css';


const Diagram = () => {
  const mqttData = useContext(MQTTContext);

  const Flow_FVE_HOUSE = mqttData.powerData.AC_Power_Consumption !== undefined &&
                          mqttData.powerData.AC_Power_Own !== undefined &&
                          parseFloat(mqttData.powerData.AC_Power_Own) > 0.0 &&
                          parseFloat(mqttData.powerData.AC_Power_Consumption) > 0;

  const Flow_FVE_BESS = mqttData.powerData.AC_Power_Own !== undefined && 
                        mqttData.powerData.AC_Power_Total_EL2 !== undefined &&
                        mqttData.powerData.AC_Power_Consumption !== undefined &&
                        parseFloat(mqttData.powerData.AC_Power_Own) > 0.0 &&
                        parseFloat(mqttData.powerData.AC_Power_Total_EL2) < 0 &&
                        parseFloat(mqttData.powerData.AC_Power_Consumption) < parseFloat(mqttData.powerData.AC_Power_Own);

  const Flow_FVE_GRID = mqttData.powerData.AC_Power_Own !== undefined && 
                        mqttData.powerData.AC_Power_Total_EL2 !== undefined && 
                        mqttData.powerData.AC_Power_Main !== undefined && 
                        mqttData.powerData.AC_Power_Consumption !== undefined &&
                        parseFloat(mqttData.powerData.AC_Power_Own) > 0.0 && 
                          ((parseFloat(mqttData.powerData.AC_Power_Total_EL2) < 0 && ((-parseFloat(mqttData.powerData.AC_Power_Total_EL2)) + parseFloat(mqttData.powerData.AC_Power_Consumption)) < parseFloat(mqttData.powerData.AC_Power_Own)) || /*Baterka se nabíjí*/
                          (parseFloat(mqttData.powerData.AC_Power_Total_EL2) >= 0 && parseFloat(mqttData.powerData.AC_Power_Consumption) < parseFloat(mqttData.powerData.AC_Power_Own)))&& /*Baterka se vybíjí*/
                        parseFloat(mqttData.powerData.AC_Power_Main) < 0;
  
  const Flow_BESS_HOUSE = mqttData.powerData.AC_Power_Total_EL2 !== undefined &&
                          mqttData.powerData.AC_Power_Consumption !== undefined &&
                          mqttData.powerData.AC_Power_Own !== undefined &&
                          parseFloat(mqttData.powerData.AC_Power_Total_EL2) > 0 &&
                          parseFloat(mqttData.powerData.AC_Power_Consumption) > 0;

  const Flow_BESS_GRID = mqttData.powerData.AC_Power_Total_EL2 !== undefined &&
                          mqttData.powerData.AC_Power_Consumption !== undefined &&
                          mqttData.powerData.AC_Power_Own !== undefined &&
                          mqttData.powerData.AC_Power_Main !== undefined &&
                          parseFloat(mqttData.powerData.AC_Power_Total_EL2) > 0 &&
                          parseFloat(mqttData.powerData.AC_Power_Consumption) < (parseFloat(mqttData.powerData.AC_Power_Own) + parseFloat(mqttData.powerData.AC_Power_Total_EL2)) &&
                          parseFloat(mqttData.powerData.AC_Power_Main) < 0;

  const Flow_GRID_BESS = mqttData.powerData.AC_Power_Total_EL2 !== undefined &&
                          mqttData.powerData.AC_Power_Consumption !== undefined &&
                          mqttData.powerData.AC_Power_Own !== undefined &&
                          mqttData.powerData.AC_Power_Main !== undefined &&
                          parseFloat(mqttData.powerData.AC_Power_Main) > 0 &&
                          parseFloat(mqttData.powerData.AC_Power_Total_EL2) < 0 &&
                          parseFloat(mqttData.powerData.AC_Power_Total_EL2) > (parseFloat(mqttData.powerData.AC_Power_Own) - parseFloat(mqttData.powerData.AC_Power_Consumption));

  const Flow_GRID_HOUSE = mqttData.powerData.AC_Power_Consumption !== undefined && 
                          mqttData.powerData.AC_Power_Main !== undefined && 
                          parseFloat(mqttData.powerData.AC_Power_Consumption) > 0.0 && 
                          parseFloat(mqttData.powerData.AC_Power_Main) > 0;

  /*
    <h1>Power Flow</h1>
    <div className="power-flow">
      <div className="diagram-item power-source">
        <img src="/images/FVE.png" alt="Solar" className="icon" />
        <div className="diagram-title">Výkon FVE</div>
        <div className="diagram-value">8.1 kW</div>
      </div>
      <div className="diagram-item bess">
        <img src="/images/BESS.png" alt="BESS" className="icon" />
        <div className="diagram-title">Výkon BESS</div>
        <div className="diagram-value">-0.6 kW</div>
      </div>
      <div className="diagram-item grid">
        <img src="/images/DS.png" alt="Grid" className="icon" />
        <div className="diagram-title">Dodávka Z/DO DS</div>
        <div className="diagram-value">8.1 kW</div>
      </div>
      <div className="diagram-item consumption">
        <img src="/images/centralni_objekt.png" alt="Spotřeba areálu" className="icon" />
        <div className="diagram-title">Spotřeba areálu</div>
        <div className="diagram-value">43.4 kW</div>
      </div>
    </div>
  */

  return (
    <div className="diagram-container">
    <div class="diagram-wrapper">
       <div class="diagram-bar">
          <h2>ZOBRAZENÍ TOKŮ ENERGIÍ</h2>
        </div>
      
        <div className="energy-flow-image">
        <img src="/images/energy-flow.png" alt="Energy Flow"/>
        </div>

      <div class="diagram">
        <div class="diagram-item">
          <svg width="700" height="500">
            <style>
              {
                `.diagram-title {
                  fill: #e96323;
                  font: bold 25px 'Roboto', sans-serif;
                }
                .diagram-value {
                  fill: white;
                  font: bold 17px 'Roboto', sans-serif;
                }`
              }
            </style>
            <g transform="translate(275, 10)">
              <rect rx="5" ry="5" width="150" height="150" fill="rgba(0, 0, 0, 0.8)" stroke="#e96323" strokeWidth="2" class="shadow" />
              <image href="/images/FVE.png" height="50" width="50" x="50" y="10"/>
              <text x="75" y="80" className="diagram-title" dominant-baseline="middle" text-anchor="middle">FVE</text>
              <text x="75" y="110" className="diagram-value" dominant-baseline="middle" text-anchor="middle">{mqttData.powerData.AC_Power_Own !== undefined ? `${mqttData.powerData.AC_Power_Own} kW` : 'Načítám...'}</text>
            </g>
            <g transform="translate(10, 175)">
              <rect rx="5" ry="5" width="150" height="150" fill="rgba(0, 0, 0, 0.8)" stroke="#e96323" strokeWidth="2" class="shadow" />
              <image href="/images/BESS.png" height="50" width="50" x="50" y="10" />
              <text x="75" y="80" class="diagram-title" dominant-baseline="middle" text-anchor="middle">BESS</text>
              <text x="75" y="110" className="diagram-value" dominant-baseline="middle" text-anchor="middle">{mqttData.powerData.AC_Power_Total_EL2 !== undefined ? `${mqttData.powerData.AC_Power_Total_EL2} kW` : 'Načítám...'}</text>
            </g>
            <g transform="translate(545, 175)">
              <rect rx="5" ry="5" width="150" height="150" fill="rgba(0, 0, 0, 0.8)" stroke="#e96323" strokeWidth="2" class="shadow" />
              <image href="/images/DS.png" height="50" width="50" x="50" y="10" />
              <text x="75" y="80" class="diagram-title" dominant-baseline="middle" text-anchor="middle">SÍŤ</text>
              <text x="75" y="110" className="diagram-value" dominant-baseline="middle" text-anchor="middle">{mqttData.powerData.AC_Power_Main !== undefined ? `${mqttData.powerData.AC_Power_Main} kW` : 'Načítám...'}</text>
            </g>
            <g transform="translate(275, 345)">
              <rect rx="5" ry="5" width="150" height="150" fill="rgba(0, 0, 0, 0.8)" stroke="#e96323" strokeWidth="2" class="shadow" />
              <image href="/images/centralni_objekt.png" height="50" width="50" x="50" y="10" />
              <text x="75" y="80" class="diagram-title" dominant-baseline="middle" text-anchor="middle">AREÁL</text>
              <text x="75" y="110" className="diagram-value" dominant-baseline="middle" text-anchor="middle">{mqttData.powerData.AC_Power_Consumption !== undefined ? `${mqttData.powerData.AC_Power_Consumption} kW` : 'Načítám...'}</text>
            </g>
            { Flow_FVE_HOUSE &&
              <g>
                <path d="M 350 161 L 350 344" stroke="white" fill="transparent" class="shadow"/>
                <circle fill="#E96323" cx="350" cy="161" r="5" class="shadow">
                  <animate attributeName="cy" values="161;344" dur="2s" repeatCount="indefinite"></animate>
                </circle>
              </g>
            }
            { Flow_FVE_GRID &&
              <g>
                <path d="M 360 161 C 360 240 360 240 544 240" stroke="white" fill="transparent" class="shadow"/>
                <circle fill="#E96323" cx="360" cy="161" r="5" class="shadow">
                  <animateMotion path="M 0 0 C 0 79 0 79 184 79" begin="0s" dur="2s" repeatCount="indefinite" />
                </circle>
              </g>
            }
            { Flow_FVE_BESS &&
              <g>
                <path d="M 340 161 C 340 240 340 240 161 240" stroke="white" fill="transparent" class="shadow"/>
                <circle fill="#E96323" cx="340" cy="161" r="5" class="shadow">
                  <animateMotion path="M 0 0 C 0 79 0 79 -184 79" begin="0s" dur="2s" repeatCount="indefinite" />
                </circle>
              </g>
            }
            { Flow_BESS_GRID &&
              <g>
                <path d="M 160 250 L 545 250" stroke="white" fill="transparent" class="shadow"/>
                <circle fill="#E96323" cx="350" cy="250" r="5" class="shadow">
                  <animate attributeName="cx" values="160;545" dur="2s" repeatCount="indefinite"></animate>
                </circle>
              </g>
            }
            { Flow_GRID_BESS &&
              <g>
                <path d="M 160 250 L 545 250" stroke="white" fill="transparent" class="shadow"/>
                <circle fill="#E96323" cx="350" cy="250" r="5" class="shadow">
                  <animate attributeName="cx" values="545;160" dur="2s" repeatCount="indefinite"></animate>
                </circle>
              </g>
            }
            { Flow_BESS_HOUSE &&
              <g>
                <path d="M 161 260 C 340 260 340 260 340 344" stroke="white" fill="transparent" class="shadow"/>
                <circle fill="#E96323" cx="161" cy="260" r="5" class="shadow">
                  <animateMotion path="M 0 0 C 179 0 179 0 179 84" begin="0s" dur="2s" repeatCount="indefinite" />
                </circle>
              </g>
            }
            { Flow_GRID_HOUSE &&
              <g>
                <path d="M 360 344 C 360 260 360 260 544 260" stroke="white" fill="transparent" class="shadow"/>
                <circle fill="#E96323" cx="544" cy="260" r="5" class="shadow">
                  <animateMotion path="M 0 0 C -184 0 -184 0 -184 84" begin="0s" dur="2s" repeatCount="indefinite" />
                </circle>
              </g>
            }
          </svg>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Diagram;
