import React, { useContext } from 'react';
import './FVEOverview.css';
import { MQTTContext } from './MQTTConn';

const FVEOverview = () => {
  const mqttData = useContext(MQTTContext);

  // Kontrola, zda mqttData a INVData existují
  if (!mqttData || !mqttData.INVData) {
    return <div>Načítám data...</div>;
  }

  // Kontrola, zda INV1 nebo INV2 existují
  if (!mqttData.INVData.INV1 && !mqttData.INVData.INV2) {
    return <div>Načítám data střídačů...</div>;
  }

  // Výpočet úrovně výkonu pro INV1
  const powerLevel_INV1 = mqttData.INVData.INV1.P_Total || 0;
  const maxPower_INV1 = 100;
  const powerLevelWidth_INV1 = `${(powerLevel_INV1 / maxPower_INV1) * 100}%`;
  
  // Výpočet úrovně výkonu pro INV2
  const powerLevel_INV2 = mqttData.INVData.INV2.P_Total || 0;
  const maxPower_INV2 = 17;
  const powerLevelWidth_INV2 = `${(powerLevel_INV2 / maxPower_INV2) * 100}%`;

  return (
    <div className="fve-overview">
      {/* Přehled Střídače INV1 */}
      <div className="inverter-wrapper">
        {/* Horní lišta střídače */}
        <div className="inverter-top-bar">
          <h2>STŘÍDAČ Č.&nbsp;1 - SOLAREDGE SE100K</h2>
        </div>

        {/* Obrázek střídače */}
        <div className="inventer-top-bar-image">
          <img src="/images/inventer.png" alt="Inventer" />
        </div>

        {/* Blok střídače */}
        <div className="inverter-block">
          <div className="inverter-content">
            {/* Stejnosměrná část střídače */}
            <div className="dc-section">
              <h3>STEJNOSMĚRNÁ ČÁST STŘÍDAČE:</h3>
              <div className="parameter-table dc-table">
                <div className="parameter-cell parameter-header parameter-title"></div>
                <div className="parameter-cell parameter-header">Hodnota</div>
                <div className="parameter-cell parameter-title">Činný výkon:</div>
                <div className="parameter-cell">xx kW</div>
                <div className="parameter-cell parameter-title">Proud AC:</div>
                <div className="parameter-cell">xx A</div>
                <div className="parameter-cell parameter-title">Účiník:</div>
                <div className="parameter-cell">xx </div>
                <div className="parameter-cell parameter-title">Napětí AC:</div>
                <div className="parameter-cell">xx V</div>
              </div>
            </div>

            {/* Obrázek střídače a šipky */}
            <div className="inverter-image">
              <img src="/images/se100k.png" alt="SE100k" />
              {powerLevel_INV1 > 0 && (
                <>
                  {/* Kontejner pro levé šipky mezi DC boxem a střídačem */}
                  <div className="arrow-container-left">
                    <svg
                      className="arrow-svg"
                      viewBox="0 0 150 60"
                      width="150"
                      height="60"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                      <path d="M40 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                      <path d="M70 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    </svg>
                  </div>

                  {/* Kontejner pro pravé šipky mezi střídačem a AC boxem */}
                  <div className="arrow-container-right">
                    <svg
                      className="arrow-svg"
                      viewBox="0 0 150 60"
                      width="150"
                      height="60"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                      <path d="M40 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                      <path d="M70 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    </svg>
                  </div>
                </>
              )}
              {/* Kontejner pro ukazatel výkonu */}
              <div className="power-container">
                <div className="power-info">AKTUÁLNÍ VÝKON:</div>
                <div className="power-bar">
                  <div className="power-level" style={{ width: powerLevelWidth_INV1 }}></div>
                  <div className="power-value">{powerLevel_INV1} kW</div> {/* Nový element */}
                </div>
                <div className="power-scale">
                  <span>0 kW</span>
                  <span>50 kW</span>
                  <span>100 kW</span>
                </div>
              </div>
            </div>

            {/* Střídavá část střídače vpravo */}
            <div className="ac-section">
              <h3>STŘÍDAVÁ ČÁST STŘÍDAČE:</h3>
              <div className="parameter-table ac-table">
                <div className="parameter-cell parameter-header parameter-title"></div>
                <div className="parameter-cell parameter-header">1.FÁZE</div>
                <div className="parameter-cell parameter-header">2.FÁZE</div>
                <div className="parameter-cell parameter-header">3.FÁZE</div>
                <div className="parameter-cell parameter-title">Činný výkon:</div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV1.P_L1 !== undefined ? `${mqttData.INVData.INV1.P_L1} kW` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV1.P_L2 !== undefined ? `${mqttData.INVData.INV1.P_L2} kW` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV1.P_L3 !== undefined ? `${mqttData.INVData.INV1.P_L3} kW` : 'Načítám...'}
                </div>
                <div className="parameter-cell parameter-title">Proud AC:</div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV1.I_L1 !== undefined ? `${mqttData.INVData.INV1.I_L1} A` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV1.I_L2 !== undefined ? `${mqttData.INVData.INV1.I_L2} A` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV1.I_L3 !== undefined ? `${mqttData.INVData.INV1.I_L3} A` : 'Načítám...'}
                </div>
                <div className="parameter-cell parameter-title">Účiník:</div>
                <div className="parameter-cell parameter-merged">
                  {mqttData.INVData.INV1.PF !== undefined ? `${mqttData.INVData.INV1.PF}` : 'Načítám...'}
                </div>
                <div className="parameter-cell parameter-title">Napětí AC:</div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV1.U_L1N !== undefined ? `${mqttData.INVData.INV1.U_L1N} V` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV1.U_L2N !== undefined ? `${mqttData.INVData.INV1.U_L2N} V` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV1.U_L3N !== undefined ? `${mqttData.INVData.INV1.U_L3N} V` : 'Načítám...'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Střídač INV 2 - 17K */}
      <div className="inverter-wrapper">
        {/* Horní lišta střídače */}
        <div className="inverter-top-bar">
          <h2>STŘÍDAČ Č.&nbsp;2 - SOLAREDGE SE17K</h2>
        </div>

        {/* Obrázek střídače */}
        <div className="inventer-top-bar-image">
          <img src="/images/inventer.png" alt="Inventer" />
        </div>

        {/* Blok střídače */}
        <div className="inverter-block">
          <div className="inverter-content">
            {/* Stejnosměrná část střídače vlevo */}
            <div className="dc-section">
              <h3>STEJNOSMĚRNÁ ČÁST STŘÍDAČE:</h3>
              <div className="parameter-table dc-table">
                <div className="parameter-cell parameter-header parameter-title"></div>
                <div className="parameter-cell parameter-header">Hodnota</div>
                <div className="parameter-cell parameter-title">Činný výkon:</div>
                <div className="parameter-cell">xx W</div>
                <div className="parameter-cell parameter-title">Proud AC:</div>
                <div className="parameter-cell">xx A</div>
                <div className="parameter-cell parameter-title">Účiník:</div>
                <div className="parameter-cell">xx</div>
                <div className="parameter-cell parameter-title">Napětí AC:</div>
                <div className="parameter-cell">xx V</div>
              </div>
            </div>

            {/* Obrázek střídače a šipky */}
            <div className="inverter-image">
              <img src="/images/se17k.png" alt="SE17k" />
              {powerLevel_INV2 > 0 && (
                <>
                  {/* Kontejner pro levé šipky mezi DC boxem a střídačem */}
                  <div className="arrow-container-left">
                    <svg
                      className="arrow-svg"
                      viewBox="0 0 150 60" 
                      width="150"
                      height="60" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                      <path d="M40 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                      <path d="M70 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    </svg>
                  </div>

                  {/* Kontejner pro pravé šipky mezi střídačem a AC boxem */}
                  <div className="arrow-container-right">
                    <svg
                      className="arrow-svg"
                      viewBox="0 0 150 60" // Šířku jsme zvětšili, aby se všechny šipky vešly
                      width="150" // Zadejte požadovanou šířku pro celý SVG element
                      height="60" // Zadejte požadovanou výšku pro celý SVG element
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                      <path d="M40 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                      <path d="M70 10 l10 20 -10 20" fill="none" stroke="#E96323" strokeWidth="5" />
                    </svg>
                  </div>
                </>
              )}
              {/* Kontejner pro ukazatel výkonu */}
              <div className="power-container">
                <div className="power-info">AKTUÁLNÍ VÝKON:</div>
                <div className="power-bar">
                  <div className="power-level" style={{ width: powerLevelWidth_INV2 }}></div>
                  <div className="power-value">{powerLevel_INV2} kW</div> {/* Nový element */}
                </div>
                <div className="power-scale">
                  <span>0 kW</span>
                  <span>8.5 kW</span>
                  <span>17 kW</span>
                </div>
              </div>
            </div>

            {/* Střídavá část střídače vpravo */}
            <div className="ac-section">
              <h3>STŘÍDAVÁ ČÁST STŘÍDAČE:</h3>
              <div className="parameter-table ac-table">
                <div className="parameter-cell parameter-header parameter-title"></div>
                <div className="parameter-cell parameter-header">1.FÁZE</div>
                <div className="parameter-cell parameter-header">2.FÁZE</div>
                <div className="parameter-cell parameter-header">3.FÁZE</div>
                <div className="parameter-cell parameter-title">Činný výkon:</div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV2.P_L1 !== undefined ? `${mqttData.INVData.INV2.P_L1} kW` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV2.P_L2 !== undefined ? `${mqttData.INVData.INV2.P_L2} kW` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV2.P_L3 !== undefined ? `${mqttData.INVData.INV2.P_L3} kW` : 'Načítám...'}
                </div>
                <div className="parameter-cell parameter-title">Proud AC:</div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV2.I_L1 !== undefined ? `${mqttData.INVData.INV2.I_L1} A` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV2.I_L2 !== undefined ? `${mqttData.INVData.INV2.I_L2} A` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV2.I_L3 !== undefined ? `${mqttData.INVData.INV2.I_L3} A` : 'Načítám...'}
                </div>
                <div className="parameter-cell parameter-title">Účiník:</div>
                <div className="parameter-cell parameter-merged">
                  {mqttData.INVData.INV2.PF !== undefined ? `${mqttData.INVData.INV2.PF}` : 'Načítám...'}
                </div>
                <div className="parameter-cell parameter-title">Napětí AC:</div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV2.U_L1N !== undefined ? `${mqttData.INVData.INV2.U_L1N} V` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV2.U_L2N !== undefined ? `${mqttData.INVData.INV2.U_L2N} V` : 'Načítám...'}
                </div>
                <div className="parameter-cell">
                  {mqttData.INVData.INV2.U_L3N !== undefined ? `${mqttData.INVData.INV2.U_L3N} V` : 'Načítám...'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FVEOverview;
