import React, { useContext } from 'react';
import './Home.css';
import { MQTTContext } from './MQTTConn';
import { motion } from 'framer-motion'; // Import Framer Motion

const Home = () => {
  const mqttData = useContext(MQTTContext);

  // Definování variant pro kontejner a jednotlivé boxy
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // Zpoždění mezi animacemi jednotlivých dětí
      },
    },
  };

  const boxVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  // Funkce pro generování informačního boxu
  const renderInfoBox = (id, x, y, icon, title, power) => {
    // Výchozí popisek
    let label = "VÝKON:";

    // Pokud je to "DISTRIBUČNÍ SOUSTAVA", uprav popisek na základě hodnoty power
    if (id === "distr-box") {
      if (mqttData.powerData?.AC_Power_Main < 0) {
        label = "EXPORT:";
      } else if (mqttData.powerData?.AC_Power_Main >= 0) {
        label = "PŘÍKON:";
      }
    }

    if (id === "bess-box") {
      if (mqttData.bessData.Status_BESS === "Vypnuto") {
        label = "STANDBY:";
      } else if (mqttData.powerData?.AC_Power_Total_EL2 < 0) {
        label = "NABÍJENÍ:";
      } else if (mqttData.powerData?.AC_Power_Total_EL2 >= 0) {
        label = "VYBÍJENÍ:";
      }
    }

    return (
      <motion.g
        className="info-box"
        id={id}
        key={id}
        variants={boxVariants} // Aplikace variant na jednotlivý box
      >
        <rect
          x={x}
          y={y}
          width="450"
          height="120" // Výška boxu
          fill="rgba(0, 0, 0, 0.8)"
          stroke="#e96323"
          strokeWidth="4"
          rx="10" // Zkosené rohy
          ry="10"
        />
        <polygon
          points={`${x + 20},${y + 120} ${x + 80},${y + 120} ${x + 50},${y + 150}`}
          fill="#e96323" // Barva trojúhelníku (šipky)
        />
        <image href={icon} x={x + 10} y={y + 20} width="80" height="80" /> {/* Velikost ikony */}
        <text
          x={x + 100} // Pozice textu
          y={y + 50} // Výška prvního řádku textu
          fill="white"
          fontSize="26" // Velikost písma
          fontFamily="Roboto, sans-serif"
          fontWeight="bold"
        >
          {title}
        </text>
        <text
          x={x + 100} // Pozice textu
          y={y + 90} // Výška druhého řádku textu
          fill="white"
          fontSize="23" // Velikost písma
          fontFamily="Roboto, sans-serif"
          fontWeight="bold"
        >
          {label} {power !== undefined ? power.toLocaleString('cs-CZ') : 'Načítám...'} kW
        </text>
      </motion.g>
    );
  };

  return (
    <div className="home-page">
      <div className="image-container">
        <img
          src="/images/male_sapeli_prehled.png"
          alt="Sapeli Areál"
          className="site-image"
        />
        <motion.svg
          className="overlay-svg"
          viewBox="0 0 1920 1080"
          preserveAspectRatio="xMidYMid meet"
          variants={containerVariants} // Aplikace variant na SVG kontejner
          initial="hidden" // Výchozí stav
          animate="visible" // Cílový stav
        >
          {/* Generování boxů na základě dat */}
          {renderInfoBox(
            "fve-box",
            400, // 25% z 1920
            300, // 30% z 1080
            "/images/FVE.png",
            "FVE O VÝKONU 138,18 kWp",
            mqttData.powerData?.AC_Power_Total_EL3
          )}

          {renderInfoBox(
            "distr-box",
            1600, // 75% z 1920
            90,   // 9% z 1080
            "/images/DS.png",
            "DISTRIBUČNÍ SOUSTAVA",
            mqttData.powerData?.AC_Power_Main
          )}

          {renderInfoBox(
            "bess-box",
            965,  // 50% z 1920
            260,  // 26% z 1080
            "/images/BESS.png",
            "BESS O KAPACITĚ 300 kWh",
            mqttData.powerData?.AC_Power_Total_EL2
          )}
        </motion.svg>
      </div>
    </div>
  );
};

export default Home;
