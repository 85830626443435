import React, { createContext, useEffect, useState } from 'react';
import mqtt from 'mqtt';

export const MQTTContext = createContext();

export const MQTTContextProvider = ({ children }) => {
  const [powerData, setPowerData] = useState({});
  const [bessData, setBESSData] = useState({});
  const [dateData, setDateData] = useState({});
  const [daySumData, setDaySumData] = useState({});
  const [energySumData, setEnergySumData] = useState({});
  const [timeData, setTimeData] = useState('Načítám...');
  const [INVData, setINVData] = useState({});
  const [forecastData, setForecastData] = useState({});
  const [dayEnergyData, setDayEnergyData] = useState({});
  const [spotPricesData, setSpotPricesData] = useState({});
  const [actualSpotPriceData, setActualSpotPriceData] = useState({});
  const [summarySpotPriceData, setSummarySpotPriceData] = useState({});
  const [BESSCyclesData, setBESSCyclesData] = useState({});

  const getClientId = () => {
    console.log('Set MQTT Broker...');
    return `mqttjs_ + ${Math.random().toString(16).substr(2, 8)}`;
  };

  useEffect(() => {
    const clientId = getClientId();
    const client = mqtt.connect('wss://pav.sapeli.metrica.cz:9001', {
      clientId: clientId,
      username: 'mendry',
      password: 'Kraken14',
      clean: true,
      rejectUnauthorized: false
    });

    client.on('connect', () => {
      console.log('Připojeno k MQTT serveru');
      client.subscribe('Metrica/Power_Meters'); // První topic
      client.subscribe('Metrica/Days'); // Druhý topic
      client.subscribe('Metrica/Time');
      client.subscribe('Metrica/BESS');
      client.subscribe('Metrica/DaySum');
      client.subscribe('Metrica/EnergySum');
      client.subscribe('Metrica/INV');
      client.subscribe('Metrica/Forecast');
      client.subscribe('Metrica/DayEnergy');
      client.subscribe('Metrica/SpotPrices');
      client.subscribe('Metrica/ActualSpotPrice');
      client.subscribe('Metrica/SpotPricesSum');
      client.subscribe('Metrica/BESS_Cycles_Summary');
    });

    client.on('error', (err) => {
      console.error('MQTT Connection error: ', err);
      client.end();
    });

    client.on('message', (topic, message) => {
      if (topic === 'Metrica/Power_Meters') {
        const payload = JSON.parse(message.toString());
        setPowerData(payload);
      } else if (topic === 'Metrica/Days') {
        const datePayload = JSON.parse(message.toString());
        setDateData(datePayload); // Zpracování časových dat
      } else if (topic === 'Metrica/Time') {
        setTimeData(message.toString()); // Zpracování časových dat
      } else if (topic === 'Metrica/BESS') {
        const bessPayload = JSON.parse(message.toString());
        setBESSData(bessPayload); 
      } else if (topic === 'Metrica/DaySum') {
        const daySumPayload = JSON.parse(message.toString());
        setDaySumData(daySumPayload); 
      } else if (topic === 'Metrica/EnergySum') {
        const energySumPayload = JSON.parse(message.toString());
        setEnergySumData(energySumPayload); 
      } else if (topic === 'Metrica/INV') {
        const INVPayload = JSON.parse(message.toString());
        setINVData(INVPayload); 
      } else if (topic === 'Metrica/Forecast') {
        const forecastPayload = JSON.parse(message.toString());
        setForecastData(forecastPayload); 
      } else if (topic === 'Metrica/DayEnergy') {
        const dayEnergyPayload = JSON.parse(message.toString());
        setDayEnergyData(dayEnergyPayload); 
      } else if (topic === 'Metrica/SpotPrices') {
        const spotPricesPayload = JSON.parse(message.toString());
        setSpotPricesData(spotPricesPayload); 
      } else if (topic === 'Metrica/ActualSpotPrice') {
        const actualSpotPricePayload = JSON.parse(message.toString());
        setActualSpotPriceData(actualSpotPricePayload); 
      } else if (topic === 'Metrica/SpotPricesSum') {
        const summarySpotPricePayload = JSON.parse(message.toString());
        setSummarySpotPriceData(summarySpotPricePayload); 
      } else if (topic === 'Metrica/BESS_Cycles_Summary') {
        const BESSCyclesPayload = JSON.parse(message.toString());
        setBESSCyclesData(BESSCyclesPayload); 
      }
    });

    return () => client.end();
  }, []);
    
    return (
      <MQTTContext.Provider value={{powerData, dateData, timeData, bessData, daySumData, energySumData, INVData, forecastData, dayEnergyData, spotPricesData, actualSpotPriceData, summarySpotPriceData, BESSCyclesData}}>
        {children}
      </MQTTContext.Provider>
    );
  };